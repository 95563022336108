  .lightbox_container {
    z-index: 1;
    width: 100%;
    height: 100%;
    position:fixed;
    left:0;
    top: 0;
  }
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.92);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .lightbox_thumbnail {
    cursor: pointer;
  }

  .lightbox_button {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: 1px #fff none;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
    background-color: #fafafa;
    padding: 0;
  }
/* laptops */
@media screen and (min-width: 1300px) {


  .lightbox_image {
    max-height: 90vh;
    width: calc(100vw - 280px);
    object-fit:scale-down;
    background-color: #fff;
  }


  .lightbox_button:hover {
    background-color: #f8f8f8;
  }

  .lightbox-icon {
    font-size: 32px;
  }
  .close {
    position: fixed;
    top: 40px;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
  }
}

/* laptops */
@media screen and (min-width: 400px) and (max-width: 1300px) {
    .lightbox_image {
        height: 90vh;
        max-width: 90vw;
        object-fit:scale-down;
    background-color: #fff;
    }
    .close {
        position: fixed;
        top:20px;
        right: 0;
        background-color: rgba(255, 255, 255, 0);
    }
}

/* phones */
@media screen and (max-width: 900px) {

  .lightbox_container {
    display: none;
  }
}