.app {
  background-color: #F1EFEB;
  width: 100%;
  padding: env(safe-area-inset);
  margin: 0;
  flex-direction: column;
  display: flex;
  font-family: 'Helvetica neue';
}
.header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
}
.logo-stuff {
  display: flex;
  height: 20vh;
  justify-content: center;
  align-items: center;
}
.intro-text {
  min-height: 50vh;
  display: flex;
  width: min(max(50vw, 330px), 640px);
  line-height: 135%;
  flex-direction: column;
}
.logo {
  position: absolute;
  width:min(max(50vw,330px), 640px);
  fill: #F23A11;
  mix-blend-mode: darken;
}
.pluie-header-text {
  width: min(max(55vw,350px), 660px);
  mix-blend-mode: darken;
}
.middle-container {
  line-height: 150%;
  display: flex;
  flex-direction: column;
  width: min(max(100%, 330px), 640px);
  align-items: center;
  align-self: center;
}
.float-footer {
  position: relative;
  width: 96vw;
  display: flex;
  flex-direction: row;
  bottom: 40px;
  justify-content: center;
}
.email {
  bottom: 40px;
  right: 60px;
  color: #1531D3;
  text-decoration: none;
  width:50%;
  text-align: right;
}
.work-with-me {
  width:50%;
  display:flex;
  align-items: center;
}
.work {
  display:flex;
  flex-direction: column;
  background-color: #fbfaf9;
  padding: 20px;
}
.header2 {
  color:#F1EFEB;
  letter-spacing: -2px;
  padding-bottom: 24px;
  line-height: 150%;
}
.header2.light {
  color: #fff;
}
.header3 {
  color:#0a0a0a;
  line-height: 150%;
}
.project {
  width:100%;
}
.project-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}
.project-info-text {
  font-weight:500;
  color:#3a3a39;
  padding-right: 8px;
}
.separator-dot {
  width: 4px;
  height: 4px;
  background-color:#F13A11;
  border-radius: 2px;
  align-self: center;
  margin-right: 8px;
}
.header4 {
  font-weight:700;
  color:#0a0a0a;
  padding-top: 20px;
}
.image {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.image:hover {
  filter: drop-shadow(0px 1px 30px rgba(0, 0, 0, 0.12));
}

.image-shadow {
  filter: drop-shadow(0px 1px 30px rgba(0, 0, 0, 0.1));
}
.quote {
  padding: 40px 20px 40px 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.profile-image {
  width: 140px;
  height: 140px;
  padding-right: 24px;
}
.quote-text {
  display:flex;
  flex-direction: column;
  margin-top: 20px;
}
.quote-name {
  margin-top:16px;
  height: 100%;
  white-space:normal;
  font-weight: 600;
}
.the-name {
  font-weight: 600;
}
.the-title {
  font-weight: 400;
}
.project-separator {
  width: 100%;
  height: 2px;
  background-color: #f0f0f0;
  margin-top: 40px;
  margin-bottom: 40px;
}
.about {
  display:flex;
  flex-direction: column;
  padding: 20px 20px 0px 20px;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.col {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.image-martin {
  width: 100%;
  height:100%;
  margin-right: 32px;
  margin-bottom:32px;
  float: left;
}
.no-margin {
  margin: 0;
}
.right-arrow {
  margin-left: 8px;
  margin-top: 4px;
  font-size: 28px;
}

.heart {
  font-size: 16px;
  padding-left: 8px;

}

.about-p {
  margin-top: 40px;
  margin-block-end: 32px;
}
/*
*
* TYPOGRAPHY AND SIZING
*
*/




/* phones */
@media screen and (max-width: 400px) {
  .h2 {
    font-size: 40px;
    font-weight:700;
  }
  .h3 {
    font-size: 24px;
    font-weight:500;
    padding-bottom: 20px;
      
    letter-spacing: -1.08px;
  }
  .h4 {
    font-size: 16px;
  }
  .copy {
    font-size: 16px;
  }
  .copy-quote {
    font-style: italic;
  }
  .copy-header {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
  }
  .work-with-me {
    visibility: hidden;
    width: 0;
  }
  .email {
    text-align: left;
  }
  .float-footer {
    width: min(max(50vw, 330px), 800px);
    justify-content: left;
  }
}


/* laptops */
@media screen and (min-width: 400px) {
  
  .intro-text {
    width: min(max(50vw, 330px), 700px);
  }
  .logo {
    width:min(max(50vw,330px), 700px);
  }
  .pluie-header-text {
    width: min(max(55vw,350px), 720px);
  }
  .middle-container {
    width: min(max(100%, 330px), 700px);
  }

  .h2 {
    font-size: 80px;
    font-weight:700;
  }
  .h3 {
    font-size: 36px;
    font-weight:500;
    letter-spacing: -1.08px;
    padding-bottom: 24px;
  }
  .h4 {
    font-size: 20px;
  }
  .copy {
    font-size: 20px;
  }
  .copy-quote {
    font-style: italic;
  }
  .copy-header {
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
  }
  .about {
    margin: 16px 48px 0px 48px;
    min-height:100vh;
  }
  .work {
    padding: 16px 48px 96px 48px;
  }
  .logo-stuff {
    height: 50vh;
  }
  .project-separator {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .project-info-text {
    font-weight:500;
    color:#333333;
    padding-right: 20px;
  }
  .separator-dot {
    width: 7px;
    height: 7px;
    background-color:#F13A11;
    border-radius: 4px;
    align-self: center;
    margin-right: 20px;
  }
  .header2 {
    padding-bottom: 80px;
  }
  .quote {
    flex-direction: row;
  }
  .quote-text {
    margin-top: 20px
  }
  .float-footer {
    position: fixed;
  }
  .work-with-me {
    visibility: visible;
  }
  .email {
    text-align: right;
  }
  .help {
    flex-direction: row;
  }
  .image-martin {
    width: 50%;
  margin-bottom: 0;
  }
}

/* phones in landscape mode */
@media only screen and (min-width: 393px) and (max-width: 900px) and (orientation: landscape) {

  .work-with-me {
    visibility: hidden;
    width: 0;
  }
  .float-footer {
    display: none;
  }
  .copy-header {
    font-size: 20px;
  }
}

/* desktops */
@media screen and (min-width: 1520px) {
  .h2 {
    font-size: 100px;
    font-weight:700;
  }
  .h3 {
    font-size: 36px;
    font-weight:500;
    letter-spacing: -1.08px;
    padding-bottom: 16px;
  }
  .h4 {
    font-size: 22px;
  }
  .copy {
    font-size: 22px;
    letter-spacing: -0.2px;
  }
  .copy-quote {
    font-style: italic;
  }
  .copy-header {
    font-size: 26px; 
    font-weight: 400;
    font-style: normal;
  }
  .logo-stuff {
    height: 40vh;
  }
}


