.dot1 {
    animation: dot1_anim 30s cubic-bezier(0.1, -0.1, 0.2, 0) infinite;
}
.dot2 {
    animation: dot2_anim 40s cubic-bezier(0.1, -0.1, 0.2, 0) infinite;
}
.dot3 {
    animation: dot3_anim 35s cubic-bezier(0.1, -0.1, 0.2, 0) infinite;
}
.dot4 {
    animation: dot4_anim 25s cubic-bezier(0.1, -0.1, 0.2, 0) infinite;
}  
  @keyframes dot1_anim {
    0% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
    50% {
      transform: translateX(30px);
      transform: translateY(10px);
    }
    100% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
  }
  @keyframes dot2_anim {
    0% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
    50% {
      transform: translateX(30px);
      transform: translateY(10px);
    }
    100% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
  }
  @keyframes dot3_anim {
    0% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
    50% {
      transform: translateX(35px);
      transform: translateY(40px);
    }
    100% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
  }
  @keyframes dot4_anim {
    0% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
    50% {
      transform: translateX(10px);
      transform: translateY(18px);
    }
    100% {
      transform: translateX(0px);
      transform: translateY(0px);
    }
  }